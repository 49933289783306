import { Box, Stat, StatLabel, StatNumber } from '@chakra-ui/react';

export type DataBoxProps = {
  value: string | number;
  helperText: string;
  fill: boolean;
};

export const DataBox = ({
  value = '',
  helperText,
  fill = false,
}: DataBoxProps) => {
  return (
    <Box
      width={240}
      height={130}
      background={fill ? 'primary' : ''}
      color={fill ? 'white' : 'primary'}
      display="flex"
      alignItems="center"
      paddingLeft="24px"
    >
      <Stat>
        <StatNumber>{value}</StatNumber>
        <StatLabel>{helperText}</StatLabel>
      </Stat>
    </Box>
  );
};
