import { Box, Text, Button } from '@chakra-ui/react';

export const GOOGLE_ADs_ICON_URL =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Google_Ads_logo.svg/250px-Google_Ads_logo.svg.png';
export const CLIENGO_ICON_URL =
  'https://theme.zdassets.com/theme_assets/1494721/62185f5c190b37bd35a3ca2bff373acebd156e56.png';

export type LinkCardProps = {
  iconURL: string;
  text: string;
  linkText: string;
};

export const LinkCard = ({ iconURL, text, linkText }: LinkCardProps) => {
  return (
    <Box
      rounded="md"
      p={4}
      border="1px solid"
      borderColor="#f1f1f1"
      display="flex"
      alignItems="center"
      boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
      mt={4}
      height={53}
    >
      <Box width="80%" display="flex" alignItems="center">
        <img
          src={iconURL}
          width={27}
          style={{ marginRight: 32 }}
          alt="imagen"
        />
        <Text fontWeight={600} fontSize={14}>
          {text}
        </Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="20%"
      >
        <Button colorScheme="primary" size="xs" width="60px">
          <Text fontSize={12}>{linkText}</Text>
        </Button>
      </Box>
    </Box>
  );
};
