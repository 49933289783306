import { useRef } from 'react';
import {
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  UnorderedList,
  ListItem,
  chakra,
  Grid,
  Text,
} from '@chakra-ui/react';

import { BotIcon } from '../BotIcon.component';

export const HelperDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  return (
    <>
      <Button
        bg="white"
        border="1px solid #4527A0"
        position="fixed"
        display="flex"
        alignItems="center"
        bottom="0"
        right="0"
        m={4}
        rounded="full"
        ref={btnRef}
        onClick={onOpen}
        py={5}
        _hover={{
          color: 'white',
        }}
      >
        <BotIcon />
        <Text
          fontWeight="bold"
          fontSize="1rem"
          color="#4527A0"
          as="span"
          mt={1}
          ml={2}
        >
          ¿Necesitas ayuda?
        </Text>
      </Button>
      <Drawer
        size="sm"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Grid
              maxW="240px"
              mx="auto"
              pb={4}
              borderBottom="2px solid #4527A0"
              templateColumns="80px 1fr"
            >
              <BotIcon />
              Bec te explica
            </Grid>
          </DrawerHeader>

          <DrawerBody>
            <UnorderedList spacing={4}>
              <ListItem>
                <chakra.strong color="#4527A0">GCLID:</chakra.strong>
                <chakra.p color="#4527A0">
                  Google Click Identificatión:
                </chakra.p>
                <chakra.p>
                  El ID de clic de Google (GCLID) es un parámetro que se
                  transfiere en la URL con los clics en el anuncio para
                  identificar la campaña y otros atributos del clic asociado con
                  el anuncio para el seguimiento de anuncios y la atribución de
                  la campaña.
                </chakra.p>
              </ListItem>

              <ListItem>
                <chakra.strong color="#4527A0">UTM:</chakra.strong>
                <chakra.p color="#4527A0">
                  Universal Transverse Mercator:
                </chakra.p>
                <chakra.p>
                  Los parámetros UTM son parámetros de URL que usa Google para
                  medir la efectividad de campañas de marketing de URLs únicas
                </chakra.p>
              </ListItem>

              <ListItem>
                <chakra.strong color="#4527A0">
                  Conversiones Offline:
                </chakra.strong>
                <chakra.p>
                  Son los datos que disponen los usuarios de manera offline, es
                  decir, a través de llamadas telefonicas, compras en locales o
                  podria ser también Live chat.
                </chakra.p>
              </ListItem>
            </UnorderedList>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
