import {
  ChakraProvider,
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
} from '@chakra-ui/react';

import { HelperDrawer } from './components/HelpDrawer/HelpDrawer.component';
import { theme } from './theme';
import {
  CLIENGO_ICON_URL,
  GOOGLE_ADs_ICON_URL,
  LinkCard,
} from './components/LinkCard/LinkCard';
import { DataBox } from './components/DataBox/DataBox';

export const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" p={8}>
        <Box pb={8}>
          <Text as="h5">Performance en Google Ads + Cliengo</Text>
          <Box width={625}>
            <LinkCard
              iconURL={GOOGLE_ADs_ICON_URL}
              text="Activar “Etiquetado automático” en Google Ads"
              linkText="Ver Guia"
            />
            <LinkCard
              iconURL={CLIENGO_ICON_URL}
              text="Aprendé a clasificar clientes en tu CRM o importá datos externos"
              linkText="Ver Guia"
            />
            <LinkCard
              iconURL={CLIENGO_ICON_URL}
              text="Ver Reporte de Google Ads"
              linkText="Ver"
            />
          </Box>
        </Box>
        <Text as="h5" color="primary" mb={4}>
          Métricas de negocio + Cliengo
        </Text>

        <Tabs>
          <TabList>
            <Tab>Últimos 30 días</Tab>
            <Tab isDisabled>Últimos 60 días</Tab>
            <Tab isDisabled>Últimos 90 días</Tab>
            <Button
              position="absolute"
              right="24px"
              borderRadius="full"
              variant="outline"
              colorScheme="primary"
              size="sm"
            >
              Exportar
            </Button>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box display="flex" width="100%" alignItems="center">
                <DataBox
                  value="$100000"
                  helperText="Gasto en campañas"
                  fill={false}
                />
                <DataBox value="7000" helperText="Ventas concretadas" fill />
                <DataBox
                  value="12"
                  helperText="Campañas activas"
                  fill={false}
                />
                <DataBox value="$12" helperText="Costo por venta" fill />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <HelperDrawer />
    </ChakraProvider>
  );
};
