import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderRadius: '5px',
  },
  sizes: {
    md: {
      minWidth: '141px',
      fontSize: '14px',
      height: '31px',
    },
    xl: {
      fontSize: '16px',
      height: '36px',
    },
    mobile: {
      fontSize: '14px',
      height: '41px',
    },
  },
  variants: {
    primary: {
      backgroundColor: 'primary',
      color: 'white',
      _hover: {
        backgroundColor: '#512DA8',
      },
    },
    secondary: {
      backgroundColor: 'secondary',
      color: 'white',
      _hover: {
        backgroundColor: '#009C74',
      },
    },
    outlinePrimary: {
      backgroundColor: 'transparent',
      color: 'primary',
      border: '1px solid',
      borderColor: 'primary',
    },
    outlineSecondary: {
      backgroundColor: 'transparent',
      color: 'secondary',
      border: '1px solid',
      borderColor: 'secondary',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
